import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import useMediaType from "../../hooks/useMediaType"
import HeaderText from "../../atoms/HeaderText"
import P from "../../atoms/P"

import Plus from "../../../assets/icons/plus.svg"
import Minus from "../../../assets/icons/minus.svg"

import InlineVideo from "../../molecules/InlineVideo"
import BannerCard from "../../molecules/BannerCard"

import VideoSrc from "../../../assets/videos/norbert_tech_intro.mp4"

export const ContainerNewFutureSection = styled.div`
  max-width: 1290px;
  margin-inline: auto;
  padding-block: 82px;
  padding-inline: 28px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding-inline: 45px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    display: grid;
    gap: 70px 60px;
    grid-template-columns: 1fr 1fr;
    padding-block: 104px 0;
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    grid-template-columns: 1fr 600px;
    gap: 70px 110px;
  }
`

//=================================HEADER WRAPPER
const HeaderWrapperNewFutureSection = styled.div`
  text-align: center;
  grid-column: 1/3;
  max-width: 903px;
  justify-self: center;
`

const StyledP = styled(P)`
  margin-block: 24px 0;
`
const StyledVideoWrapper = styled.div`
  position: relative;
  width: 880px;
  max-width: 100%;
  margin: 40px auto 15px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 70px auto 170px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    display: block;
    width: 100vw;
    height: 38px;
    transform: translateX(-50%) translateY(-50%);
    background-color: ${props => props.theme.colors.site.primaryDark};
  }
`

const StyledVideoInner = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio*/
`

const Tabs = styled.div`
  display: grid;
  gap: 12px;
  margin-block: 32px;
  place-items: start;

  @media screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 0;
  }

  @media screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    gap: 26px;
  }
`

const TabItem = styled.button`
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 8px 8px 8px 6px;
  color: #1e0e5e;
  border: 0;
  font-size: 18px;
  font-family: "Graphik Web Regular";
  font-weight: 500;
  background: transparent;
  border-bottom: 3px solid transparent;
  opacity: 0.6;
  text-align: left;
  transition: 0.3s all;

  svg {
    color: #000;
  }

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.isActive &&
    `
    color: #15C5CF;
    opacity: 1;
    border-bottom-color: #15C5CF;

    svg {
      color: #15C5CF;
    }
  `}

  @media screen and (min-width: ${props =>
    props.theme.screenBreakpoints.desktop}px) {
    font-size: 22px;
  }
`

const TabBody = styled.div`
  margin-block: 18px 10px;
  padding-left: 32px;
  max-width: 375px;

  p {
    margin: 0;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-block: 28px 0;
  }
`

// const Box = styled.div``

// const BoxStyledImage = styled(GatsbyImage)`
//   overflow: hidden;
//   border-radius: 7px;
//   border: 4px solid #f2f4f7;
//   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

//   img {
//     border-radius: 7px;
//   }
// `

const NewFutureSection = ({ content }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const mediaType = useMediaType()

  return (
    <ContainerNewFutureSection>
      <HeaderWrapperNewFutureSection>
        <HeaderText
          id="future_section_header"
          color="text"
          size={mediaType === "mobile" ? 3 : 2}
        >
          {content.header}
        </HeaderText>
        <StyledP color="text" size="medium">
          {content.body}
        </StyledP>
      </HeaderWrapperNewFutureSection>
      <div className="tab-video">
        <Tabs className="videotab">
          {content.items.map((item, index) => {
            const isActive = activeIndex === index

            return (
              <div key={index}>
                <TabItem
                  isActive={activeIndex === index}
                  type="button"
                  onClick={() => setActiveIndex(index)}
                >
                  {isActive ? <Minus /> : <Plus />}
                  {item.header}
                </TabItem>
                {isActive && (
                  <TabBody>
                    <P color="text" size="medium">
                      {content.items[activeIndex].body}
                    </P>
                  </TabBody>
                )}
              </div>
            )
          })}
        </Tabs>
        <StyledVideoWrapper className="videowrapper">
            <StyledVideoInner className="videoinner">
              <InlineVideo
                videoSrc={VideoSrc}
                videoImageData={content.items[activeIndex].video_poster}
                videoImagePosition={"left center"}
              />
            </StyledVideoInner>
          </StyledVideoWrapper>
      </div> 

      
        {/* <BoxStyledImage
          image={
            content.items[activeIndex].image_path.childImageSharp
              .gatsbyImageData
          }
          alt=""
        /> */}
      
      
    </ContainerNewFutureSection>
  )
}

export default NewFutureSection