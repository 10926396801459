import React, { useState, useEffect } from "react"
import styled from "styled-components"
import HeaderText from "../../atoms/HeaderText"
import Button from "../../atoms/Button"
import P from "../../atoms/P"
import { Modal } from "react-bootstrap"

import useMediaType from "../../hooks/useMediaType"
import PreorderForm from "../../organisms/PreorderForm"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PilotForm from "../../organisms/PilotForm"

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    max-width: 1024px;
  }
  .fade {
    padding-right: 16px;
    padding-left: 16px;
  }
`
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    margin-bottom: -10px;
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    width: 50vw;

    background: white;
    height: 100%;
    button {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    height: 100%;

    button {
      margin-left: auto;
      margin-right: auto;
      //tablet
      @media only screen and (min-width: ${props =>
          props.theme.screenBreakpoints.tabletMin}px) {
      }
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: 17px;

  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-top: 72px;
  }
`

const MobileCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  p::first-of-type {
    font-size: 48px;
    font-family: "Graphik Web Regular";
    margin-bottom: 44px;
  }
  button {
    margin-bottom: 20px;
  }
`

const HiringWrapper = styled.div`
  p {
    font-size: 14px;
  }
  min-width: 100px;
  padding: 0 10px;

  i {
    font-size: 14px;
    font-family: "Graphik Web Medium";
    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.desktopSmallMin}px) {
      font-size: 14px;
    }
    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
      margin: 0 auto;
      font-size: 14px;
    }
  }
`

const CardHeader = styled.div`
  max-width: 296px;
  p {
    line-height: 38px;
  }
  margin-top: 30px;
  margin-bottom: 30px;
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    width: 296px;
    margin-bottom: 0px;
    margin-top: 0px;

    p {
      line-height: 40px;
    }

    margin-top: 30px;
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    max-width: 500px;
    p {
      line-height: 70px;
    }
    width: 500px;
  }
`

const CardImage = styled.div`
  width: 100%;

  .card-body--image-wrapper {
    width: 338px;
    margin: 0 auto;

    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tablet}px) and (max-width: ${props =>
      props.theme.screenBreakpoints.desktopSmall}px) {
    margin: 20px auto 20px auto;
    img {
      max-height: 380px;
    }
  }
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tablet}px) {
    margin-top: 150px;
    margin: 150px auto 0 auto;
    width: 100%;
    height: 100%;
    max-height: 193px;
    img {
      max-height: 193px;
    }
  }
`

const BannerCard = ({ color, children, button_text, hiring, to }) => {
  const mediaType = useMediaType()
  const [show, setShow] = useState(false)
  const [h, setH] = useState(1)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    if (button_text === "Pre-order") setShow(true)
  }
  const [showPilot, setShowPilot] = useState(false)
  const handleShowPilot = () => setShowPilot(true)
  const handleClosePilot = () => setShowPilot(false)
  const sizeHandler = media => {
    switch (media) {
      case "desktop":
        return "hBig"
      case "tablet":
        return 3
      case "mobile":
        return 3
      default:
        return 1
    }
  }

  useEffect(() => setH(sizeHandler(mediaType)), [mediaType])

  const { file: image } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "img/device_banner-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 338, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <CardBody>
      <MobileCardBody>
        <CardImage>
          <GatsbyImage
            className="card-body--image-wrapper"
            image={image.childImageSharp.gatsbyImageData}
            loading={"eager"}
          />
        </CardImage>

        <CardHeader>
          <HeaderText size={h} color="primaryDark">
            {children}
          </HeaderText>
        </CardHeader>
        {hiring ? (
          <StyledButton
            to="https://landing.norberthealth.com/pilots"
            color={color}
          >
            {button_text.toUpperCase()}
          </StyledButton>
        ) : (
          <StyledButton color={color} onClick={handleShow}>
            {button_text.toUpperCase()}
          </StyledButton>
        )}

        {hiring && (
          <HiringWrapper>
            <i style={{ fontSize: "14px" }}>
              <P size="small">
                We’re currently accepting applications for our pilot program
              </P>
            </i>
          </HiringWrapper>
        )}
      </MobileCardBody>

      <StyledModal show={show} onHide={handleClose}>
        <PreorderForm closeMethod={handleClose} />
      </StyledModal>

      <StyledModal show={showPilot} onHide={handleClosePilot}>
        <PilotForm closeMethod={handleClosePilot} />
      </StyledModal>
    </CardBody>
  )
}

export default BannerCard
