import React from "react"
import IconLock from "../../../assets/icons/lock.svg"
import IconPhoneLap from "../../../assets/icons/icon-phone.svg"
import IconCaution from "../../../assets/icons/caution.svg"
import IconCode from "../../../assets/icons/icon-code.svg"
import IconPrivacy from "../../../assets/icons/privacy.svg"
import DescribedInformation from "../../molecules/DescribedInformation"
import styled from "styled-components"

const Content = [
  
  //{
    //icon: IconCaution,
    // iconPath: "/icon-caution.svg",
    //text: "Reports and notifications",
  //},
  
  //{
    //icon: IconPhoneLap,
    //text: "Web and mobile dashboard",
 // },
  //{
    //icon: IconCode,
    // iconPath: "/icon-code.svg",
  //  text: "SAML access control",
  //},
]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    display: flex;
    flex-direction: column-reverse;
  }

  //desktopSmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 0;
    flex-direction: row;
    display: flex;
    flex-direction: row;
  }
`

const StyledDescribedInformation = styled(DescribedInformation)`
  //desktopSmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    flex-grow: 1;
  }
`

const SoftwareInfo = () => {
  return (
    <Wrapper>
      <StyledDescribedInformation content={Content} color="light" isGrid />
    </Wrapper>
  )
}

export default SoftwareInfo
