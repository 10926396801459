import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Banner from "../components/organisms/Banner"
import UseCases from "../components/organisms/UseCases"
import VideoLine from "../components/organisms/VideoLine"
import ProductInfo from "../components/organisms/ProductInfo"
import SoftwareInfo from "../components/organisms/SoftwareInfo"
import NewFutureSection from "../components/organisms/NewFutureSection"
import Advertisement from "../components/organisms/Advertisement"
import { Modal } from "react-bootstrap"
import ThankYou from "../components/molecules/ThankYou"

import GradientBackground from "../components/atoms/GradientBackground"

import styled from "styled-components"

import Layout from "../components/Layout"
import Seo from "../components/seo"
const ContentContext = React.createContext()

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    max-width: 1024px;
  }
`
const Solutions = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "solutions_page" } } }
        ) {
          edges {
            node {
              frontmatter {
                color
                header
                banner {
                  header
                  video_poster {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                  button_text
                }
                card_section {
                  header
                  video_src
                }
                use_cases {
                  header
                  items {
                    header
                    body
                    image_path {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
                features {
                  icon_path
                  header
                  body
                  disabled
                }
                healthcare_banner {
                  header
                  body
                  items {
                    header
                    body
                    video_poster {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                  }
                }
                pilot_section {
                  header
                  paragraph
                  button_text
                }
                advertisement {
                  header
                  image_path {
                    childImageSharp {
                      gatsbyImageData(width: 480, placeholder: BLURRED)
                    }
                  }
                  description
                  button_text
                  button_link
                }
              }
            }
          }
        }
      }
    `
  )
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setShow(true)
    }
  }, [])

  return (
    <Layout>
      <Seo title="Solutions" />
      <StyledModal show={show} onHide={handleClose}>
        <ThankYou closeMethod={handleClose} color="secondary" />
      </StyledModal>
      {allMarkdownRemark.edges.map((item, i) => {
        let content = item.node.frontmatter
        return (
          <React.Fragment key={i}>
            <ContentContext.Provider value={content}>
              {/* <Banner content={content} hiring={true} /> */}
              <UseCases content={content.use_cases} />
              {/* <VideoLine content={content} /> */}
              <NewFutureSection
                content={content.healthcare_banner}
                color={content.color}
              />
              <ProductInfo fullSpecsColor={content.color} content={content} />
              <SoftwareInfo />
              <GradientBackground topBar={false}>
                <Advertisement
                  header={content.advertisement.header}
                  description={content.advertisement.description}
                  buttonColor="secondary"
                  buttonText1={content.advertisement.button_text}
                  buttonLink1={content.advertisement.button_link}
                  imagePath={content.advertisement.image_path}
                  underButton={content.advertisement.under_button}
                />
              </GradientBackground>
            </ContentContext.Provider>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export default Solutions
