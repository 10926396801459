import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Modal } from "react-bootstrap"

import useMediaType from "../../hooks/useMediaType"
import FullSpecs from "../../organisms/FullSpecs"
import P from "../../atoms/P"

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-dialog {
    max-width: 1024px;
  }
`
const StyledWrapper = styled.div`
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  //desktopSmall
  width: 100%;
  padding-bottom: ${props => (props.color !== "light" ? "88px" : "0px")};

  background-color: ${props =>
    props.color === "light"
      ? props.theme.colors.white
      : props.theme.colors.site.primaryDark};

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding-bottom: ${props => (props.color !== "light" ? 118 : 0)}px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 0 auto;
    border-top-left-radius: 90px;

    z-index: 2;
    width: 50%;

    ${props =>
      props.isGrid &&
      `
      width: 100%;
    `}
  }

  @media only screen and (min-width: 1150px) {
    ${props =>
      props.color === "light" &&
      `
    margin-left: 0px;
    padding-left: 5vw;
    margin-right: 0px;
    margin-top: 0px;`}
  }
`

const DisplayFlex = styled.div`
  display: flex;

  margin-top: 32px;

  &:first-of-type {
    margin-top: 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  p {
    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
      font-size: 22px;
      margin: auto 0 auto 40.63px;
    }

    border-top-left-radius: 90px;
    width: 100%;
    margin: 0 0px auto 40.63px;
  }
`
const ItemsWrapper = styled.div`
  justify-content: normal;
  margin: 80px 30px 88px 20px;
  width: 70vw;
  margin-left: 40px;

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: ${props =>
      props.color !== "light" ? "60px 17px 28px 90px" : "0px 17px 118px 90px"};
    ${props => props.color === "light" && "margin-top: 118px;"}
  }

  //desktopSmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 0;
    padding: ${props =>
      props.color === "light" ? "96px 80px" : "75px 0 0 45px"};
    width: 45vw;

    ${props =>
      props.isGrid &&
      `
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      margin: 0;
      padding-block: 0px;
      align-items: center;
      gap: 36px 24px;

      > div {
        margin-top: 0;
      }

      p { margin-block: 0; line-height: 1.3;}
    `}
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    padding: ${props =>
      props.color === "light" ? "0px 0px" : "85px 0 0 90px"};
  }
`
const FullSpecsStyle = styled.div`
  position: absolute;
  bottom: 0;
  background: ${props =>
    props.fullSpecsColor === "tertiary"
      ? props => props.theme.colors.site.primaryLight
      : props => props.theme.colors.site.secondaryDark};
  width: 100%;

  align-self: flex-end;
  P {
    font-size: 20px;
    margin: 24px auto 24px 88px;
    @media only screen and (max-width: ${props =>
        props.theme.screenBreakpoints.mobileMax}px) {
      margin: 24px 10px 24px 10px;

      padding-left: 28px;
    }
    text-decoration: underline;
    font-size: 20px;
    &:hover {
      color: ${props => props.theme.colors.site.primaryDark};
      cursor: pointer;
    }
  }
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  max-height: 35px;
  justify-content: center;
  max-width: 35px;
  svg {
    width: 35px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-height: 35px;
    justify-content: center;
  }

  img {
    width: 50px;
    object-fit: contain;
    margin: 0 auto;
    max-height: 50px;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  //mobile
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tablet}px) {
    width: 19.68px;

    svg {
      width: 100%;
      margin: 0 auto;
    }
  }
`
const StyledP = styled(P)`
  font-family: "Graphik Web Medium";
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  line-height: 32px;
  /* or 133% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  ${props => props.isDisabled && `color: #AFAAAD;`}
  ${props => props.isHead && `margin-left: 0 !important;`}
`
const ProductDescribedInformation = ({
  content,
  color,
  specs,
  fullSpecsColor,
  isGrid,
}) => {
  const [show, setShow] = useState(false)
  const mediaType = useMediaType()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [size, setSize] = useState()

  const sizeHandler = media => {
    switch (media) {
      case "mobile":
        return "small"
      case "tablet":
        return "large"
      case "desktopSmall":
        return "large"
      case "desktop":
        return "large"
      default:
        return "large"
    }
  }
  useEffect(() => {
    setSize(sizeHandler(mediaType))
  }, [mediaType])
console.log(content)
  return (
    <StyledWrapper color={color} isGrid={isGrid}>
      <ItemsWrapper color={color} isGrid={isGrid}>
        {content.map((item, i) => (
          <React.Fragment key={i}>
            <DisplayFlex>
            {item.iconPath && (
              <IconWrapper>
                {typeof item.iconPath !== "undefined" ? (
                  <img
                    src={item.iconPath}
                    alt={`icon-${
                      item.iconPath.split("/")[item.iconPath.length - 1]
                    }`}
                  />
                ) : (
                  <item.icon />
                )}
              </IconWrapper>
              )}
              <StyledP
                size={size}
                color={color === "light" ? "text" : "white"}
                isDisabled={item?.isDisabled}
                isHead={item?.isHead}
              >
                {item.text}
              </StyledP>
            </DisplayFlex>
          </React.Fragment>
        ))}
      </ItemsWrapper>

      {specs && (
        <FullSpecsStyle fullSpecsColor={fullSpecsColor}>
          <P color="white" size="large" onClick={handleShow}>
            SEE FULL SPECS
          </P>
        </FullSpecsStyle>
      )}

      <StyledModal show={show} onHide={handleClose}>
        <FullSpecs closeMethod={handleClose} />
      </StyledModal>
    </StyledWrapper>
  )
}

export default ProductDescribedInformation
