import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import useMediaType from "../../hooks/useMediaType"
import HeaderText from "../../atoms/HeaderText"
import P from "../../atoms/P"

import Plus from "../../../assets/icons/plus.svg"
import Minus from "../../../assets/icons/minus.svg"

export const ContainerUseCases = styled.div`
  max-width: 1290px;
  margin-inline: auto;
  padding-block: 82px;
  padding-inline: 28px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding-inline: 45px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    padding-block: 136px 0;
  }

  @media screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    padding-block: 200px 0;
    gap: 110px;
    grid-template-columns: 1fr 600px;
    width: 100%;
  }
`

//=================================HEADER WRAPPER
const HeaderWrapperUseCases = styled.div``

const Tabs = styled.div`
  display: grid;
  gap: 12px;
  margin-block: 32px;
  place-items: start;

  @media screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    gap: 26px;
    margin-block: 48px 0;
  }
`

const TabItem = styled.button`
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 8px 8px 8px 6px;
  color: #1e0e5e;
  border: 0;
  font-size: 18px;
  font-family: "Graphik Web Regular";
  font-weight: 500;
  background: transparent;
  border-bottom: 3px solid transparent;
  opacity: 0.6;
  text-align: left;
  transition: 0.3s all;

  svg {
    color: #000;
  }

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.isActive &&
    `
    color: #15C5CF;
    opacity: 1;
    border-bottom-color: #15C5CF;

    svg {
      color: #15C5CF;
    }
  `}

  @media screen and (min-width: ${props =>
    props.theme.screenBreakpoints.desktop}px) {
    font-size: 22px;
  }
`

const TabBody = styled.div`
  margin-block: 18px 10px;
  padding-left: 32px;
  max-width: 375px;

  p {
    margin: 0;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-block: 28px 0;
  }
`

const Box = styled.div``

const BoxStyledImage = styled(GatsbyImage)`
  overflow: hidden;
  border-radius: 7px;
  border-bottom: 25px solid #2e80e0;
  height: 100%;

  img {
    border-radius: 7px 7px 0 0;
  }
`

const UseCases = ({ content }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const mediaType = useMediaType()

  return (
    <ContainerUseCases>
      <HeaderWrapperUseCases>
        <HeaderText color="text" size={mediaType === "mobile" ? 3 : 2}>
          {content.header}
        </HeaderText>

        <Tabs>
          {content.items.map((item, index) => {
            const isActive = activeIndex === index

            return (
              <div>
                <TabItem
                  isActive={isActive}
                  type="button"
                  onClick={() => setActiveIndex(index)}
                >
                  {isActive ? <Minus /> : <Plus />}
                  {item.header}
                </TabItem>
                {isActive && (
                  <TabBody>
                    <P color="text" size="medium">
                      {content.items[activeIndex].body}
                    </P>
                  </TabBody>
                )}
              </div>
            )
          })}
        </Tabs>
      </HeaderWrapperUseCases>

      <Box>
        <BoxStyledImage
          image={
            content.items[activeIndex].image_path.childImageSharp
              .gatsbyImageData
          }
          alt=""
        />
      </Box>
    </ContainerUseCases>
  )
}

export default UseCases
