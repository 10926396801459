import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 6.66vw 0;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    padding: 50px 0;
  }

  .gatsby-image-wrapper {
    width: 80%;
    max-width: 768px;

    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.desktopSmallMin}px) {
      width: 100%;
    }
  }
`

const Carousel = () => {
  const { file: image } = useStaticQuery(graphql`
    {
      file(
        relativePath: { eq: "img/healthcare/image_for_described_info.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 768, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <ImageContainer>
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        objectFit="contain"
      />
    </ImageContainer>
  )
}

export default Carousel
