import React from "react"
import styled from "styled-components"

import InlineVideo from "../../molecules/InlineVideo"
import BannerCard from "../../molecules/BannerCard"

import VideoSrc from "../../../assets/videos/video_banner.mp4"

const mediaType = {
  tabletMin: props => props.theme.screenBreakpoints.tabletMin,
  desktop: props => props.theme.screenBreakpoints.desktop,
  desktopSmallMin: props => props.theme.screenBreakpoints.desktopSmallMin,
  mobile: props => props.theme.screenBreakpoints.mobile,
}

export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;

  //mobile

  height: ${props =>
    props.color !== "secondary" ? "calc(630px)" : "calc(600px + 19vw)"};
  display: flex;

  background: ${props => props.theme.colors.backgroundPrimary};
  flex-direction: column;
  position: relative;

  //tablet
  @media only screen and (min-width: ${mediaType.tabletMin}px) {
    display: flex;
    height: 100%;
    flex-direction: row;
    background: ${props => props.theme.colors.backgroundPrimary};
    position: relative;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  background-size: cover;
  height: 650px;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  height: 248px;
  display: flex;
  flex-direction: column;

  //tablet
  @media only screen and (min-width: ${mediaType.tabletMin}px) {
    display: flex;
    padding-top: 0px;
    flex-direction: row-reverse;
    height: 624px;
  }

  //desktop
  @media only screen and (min-width: ${mediaType.desktopSmallMin}px) {
    display: flex;
    flex-direction: row-reverse;

    background-size: 720px auto;
  }
`
const SpaceForImage = styled.div`
  position: relative;
  flex-grow: 1;
  width: 0px;
  max-width: 50vw;
  display: none;

  > div {
    height: 100%;
    width: 100%;
  }

  video {
    object-position: left center;
  }

  //tablet
  @media only screen and (min-width: ${mediaType.tabletMin}px) {
    display: block;
    width: 100%;
    max-width: 50vw;
  }

  //mobile
  @media only screen and (min-width: ${mediaType.desktopSmallMin}px) {
    width: 100%;
    max-width: 50vw;
    height: 100%;
  }
`
const CardWrap = styled.div`
  //desktop

  flex-grow: 1;
  width: 100%;
  text-align: center;
  justify-content: center;

  //mobile
  @media only screen and (max-width: ${mediaType.tableMin}px) {
    min-width: 100vw;
    background: transparent;
  }
`

const Banner = ({ content, hiring }) => {
  return (
    <BannerWrapper color={content.color}>
      <ContentWrapper>
        <SpaceForImage>
          <InlineVideo
            videoSrc={VideoSrc}
            videoImageData={content.banner.video_poster}
            videoImagePosition={"left center"}
          />
        </SpaceForImage>
        <CardWrap>
          <BannerCard
            button_text={content.banner.button_text}
            hiring={hiring}
            color={content.color}
          >
            {content.banner.header}
          </BannerCard>
        </CardWrap>
      </ContentWrapper>
    </BannerWrapper>
  )
}

export default Banner
