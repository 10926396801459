import React from "react"
import styled from "styled-components"
import P from "../../atoms/P"
import HeaderText from "../../atoms/HeaderText"

const ThankYouContainer = styled.div`
  position: relative;
  width: 100%;
  border-top: 20px solid
    ${props =>
      props.color !== "secondary"
        ? props => props.theme.colors.site.primaryLight
        : props => props.theme.colors.site.secondaryLight};
`
const X = styled.div`
  position: absolute;
  top: 22.5%;
  right: 19%;
  transform: scale(0.8);
  background: url("/x-icon.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
`
const ThankYouHeaderWrapper = styled.div`
  padding: 0 15px;
  margin: 100px auto 40px auto;
  text-align: center;
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: 199px auto 40px auto;
  }
`
const ThankYouParagraphWrapper = styled.div`
  margin: 0 auto 43px auto;
  text-align: center;
`
const ThankYouGoBackWrapper = styled.div`
  color: ${props => props.theme.colors.site.secondaryLight};
  text-decoration: underline;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: center;
  padding: 0 15px;
  font-family: "Graphik Web Regular";
  cursor: pointer;
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-bottom: 113px;
  }
`
const ThankYou = ({ color, closeMethod }) => {
  return (
    <ThankYouContainer>
      <X onClick={closeMethod} />

      <ThankYouHeaderWrapper>
        <HeaderText size={2} color="text">
          Thank You
        </HeaderText>
      </ThankYouHeaderWrapper>
      <ThankYouParagraphWrapper>
        <P size="regular" color="text">
          Thank you for reaching out. We’ll be in touch soon.
        </P>
      </ThankYouParagraphWrapper>
      <ThankYouGoBackWrapper>
        <P size="regular" onClick={closeMethod}>
          Go back
        </P>
      </ThankYouGoBackWrapper>
    </ThankYouContainer>
  )
}

export default ThankYou
