import React from "react"
import Carousel from "../../molecules/Carousel"
import DescribedInformation from "../../molecules/DescribedInformation"
import styled from "styled-components"

const Content = [
  {
    iconPath: "/1.svg",
    text: "Secure SaaS platform",
    isDisabled: false,
  },
  {
    iconPath: "/2.svg",
    text: "Reports and notifications",
    isDisabled: false,
  },
  {
    iconPath: "/3.svg",
    text: "Configurable privacy policies",
    isDisabled: false,
  },
  {
    iconPath: "/4.svg",
    text: "Web and mobile dashboard",
    isDisabled: false,
  },
  {
    iconPath: "/5.svg",
    text: "SAML access control",
    isDisabled: false,
  },
  // {
  //   iconPath: "",
  //   text: "✓ Scheduling visits",
  //   isDisabled: false,
  //   isHead: true,
  // },
  // {
  //   iconPath: "",
  //   text: "✓ Medication reminders",
  //   isDisabled: false,
  //   isHead: true,
  // },
  // {
  //   iconPath: "",
  //   text: "✓ Call Bell triage",
  //   isDisabled: false,
  //   isHead: true,
  // },
  // {
  //   iconPath: "",
  //   text: "✓ Patient record connection",
  //   isDisabled: false,
  //   isHead: true,
  // },
]

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background: linear-gradient(180deg, #e5e5e5 0%, rgba(255, 255, 255, 0) 100%);
  //mobile

  flex-direction: column;

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: column;
  }
  //desktopSmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-top: 96px;
    flex-direction: row;
  }
`
const CarouselWrapper = styled.div`
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    display: flex;

    flex-direction: column;
    width: 100%;
  }
  //desktopSmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    flex-grow: 1;
    width: 50%;
    flex-direction: row;
  }
`
const StyledDescribedInformation = styled(DescribedInformation)`
  //desktopSmall
  opacity: 0 !important;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmall}px) {
    flex-grow: 1;
  }
`

const ProductInfo = ({ fullSpecsColor }) => {
  return (
    <Wrapper>
      <CarouselWrapper>
        <Carousel />
      </CarouselWrapper>
      <StyledDescribedInformation
        specs
        fullSpecsColor={fullSpecsColor}
        content={Content}
      />
    </Wrapper>
  )
}

export default ProductInfo
