import React from "react"

import HeaderText from "../../atoms/HeaderText"
import useMediaType from "../../hooks/useMediaType"

import styled from "styled-components"

const StyledSection = styled.div`
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.mobileMax}px) {
    background: linear-gradient(
      175deg,
      rgba(244, 244, 244, 0.5),
      rgba(244, 244, 244, 0.5) 15%,
      rgba(244, 244, 244, 0) 60%
    );
  }
  text-align: center;
  width: 100%;
  height: 100%;
`
const StyledH = styled(HeaderText)`
  /* H3 */

  font-family: "Graphik Web Regular";
  font-style: normal;
  font-weight: 500;

  line-height: 40px;
  /* identical to box height, or 125% */

  text-align: center;
  letter-spacing: 0.2px;
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding-top: 58px;
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-left: 132px;
    margin-right: 132px;

    p {
      font-size: 30px;
    }
  }
`
const HeaderWrapper = styled.div`
  padding-top: 47px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 694px;
  margin-inline: auto;

  p {
    line-height: 32px;
    font-size: 22px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding-top: 120px;
    p {
      font-size: 30px;
    }
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmall}px) {
    p {
      font-size: 32px;
    }
  }
`

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 880px;
  max-width: 100%;
  margin: 40px auto 15px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 70px auto 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    display: block;
    width: 100vw;
    height: 38px;
    transform: translateX(-50%) translateY(-50%);
    background-color: ${props => props.theme.colors.site.primaryDark};
  }
`

const StyledVideoInner = styled.div`
  position: relative;
  padding-bottom: calc(100% / 1.78);
  background: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const VideoLine = ({ content }) => {
  const mediaType = useMediaType()
  return (
    <StyledSection>
      <HeaderWrapper>
        <StyledH size={mediaType === "mobile" ? 6 : 3} color="text">
          {content.card_section.header}
        </StyledH>
      </HeaderWrapper>

      <StyledVideoWrapper>
        <StyledVideoInner>
          <iframe
            width="100%"
            height="100%"
            src={content.card_section.video_src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            autoPlay
          />
        </StyledVideoInner>
      </StyledVideoWrapper>
    </StyledSection>
  )
}

export default VideoLine
